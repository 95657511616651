<template>
  <div class="follow-item">
    <img :src="userlist.head_pic" @click="btnheaduser" />
    <div class="follow-item-name">
      <span>{{ userlist.nickname }}</span>
      <a v-if="userlist.add_time != null">{{ userlist.add_time | dateFormat }}&nbsp;关注了你 </a>
    </div>

    <div class="follow-item-buttom" id="follow-item-buttom" @click="btncancel(userlist.store_id)">
      <div v-if="xh_collect == 1">相互关注</div>
      <div v-if="xh_collect != 1 && isfans != 1">取消关注</div>
      <div style="background-color:#fe2b54;color: #FFF;" v-if="xh_collect != 1 && isfans == 1">回粉</div>
    </div>

    <component
      v-for="(item, index) in comName"
      @btnoutother="btnoutother(index)"
      class="user-other"
      id="user-other"
      :key="item.id"
      :is="item.name"
      :userlist="userlist"
      :user_id="userlist.id"
    ></component>
  </div>
</template>

<script>
import UserOther from '../../../user/userother/UserOther'
// import {getUserFollow,followUser} from "network/follow";
import { followUser } from 'network/follow'
export default {
  name: 'followuseritem',
  components: {
    UserOther
  },
  data() {
    return {
      comName: [],
      my_id: null
    }
  },
  props: {
    userlist: {
      type: Object
    },
    isfans: {
      type: Number
    },
    xh_collect: {
      type: Number
    }
  },
  created() {
    this.my_id = window.sessionStorage.getItem('userId')
  },
  methods: {
    btnheaduser() {
      // this.comName.push({
      //     name:"UserOther"
      // })
    },
    btnoutother(index) {
      /*删除组件*/
      this.comName.splice(index, 1)
      let userother = document.getElementById('user-other')
      userother.style.left = 100 + '%'
      let noteother = document.getElementById('note-other')
      let fabsother = document.getElementById('fabs-other')
      noteother.style.display = 'none'
      fabsother.style.display = 'none'
    } /*取消关注*/,
    btncancel(store_id) {
      followUser(store_id, 1).then((res) => {
        if (res.data.status == 1) {
          this.$message('取消关注成功')
        } else {
          this.$message('网络错误!')
        }
      })
      // subfollow(this.my_id,this.userlist.id).then()
      this.$router.go(0)
    }
  }
}
</script>

<style scoped>
.follow-item {
  width: 100%;
  display: flex;
  margin: 15px 0;
  padding-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.3);
}
.follow-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}
.follow-item .follow-item-name {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.follow-item-name span {
  margin-bottom: 6px;
  font-size: 14px;
}
.follow-item-name a {
  font-size: 12px;
}
.follow-item-buttom {
  width: 120px;
}
.follow-item-buttom div {
  background-color: rgba(220, 220, 220, 0.5);
  margin-right: 5px;
  padding: 5px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}

.user-other {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  transition: all 0.5s;
}
</style>
